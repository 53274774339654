const TOKEN_KEY = 'Authorization';
const REFRESH_TOKEN = 'Refresh';
const EXPIRE_TIME = 'Expire';

const TokenService = {
    getToken() {
        return localStorage.getItem(TOKEN_KEY);
    },
    getRefreshToken() {
        return localStorage.getItem(REFRESH_TOKEN);
    },
    setToken(token) {
        localStorage.setItem(TOKEN_KEY, token);
    },
    setRefreshToken(token) {
        localStorage.setItem(REFRESH_TOKEN, token);
    },
    removeToken() {
        localStorage.removeItem(TOKEN_KEY);
    },
    removeRefreshToken() {
        localStorage.removeItem(REFRESH_TOKEN);
    },
    getExpireTime(){
        return localStorage.getItem(EXPIRE_TIME)
    },
    setExpireTime(expireTime){
        localStorage.setItem(EXPIRE_TIME,expireTime)
    },
    removeExpiredDate(){
        localStorage.removeItem(EXPIRE_TIME)
    }
};
export default TokenService;