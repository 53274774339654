import Vue from "vue";
import VueRouter from "vue-router";
import FrontLayout from "@/components/FrontLayout/FrontLayout";
import TokenService from "@/services/TokenServices";
import can from "@/can";
Vue.use(VueRouter);

const router = new VueRouter({
    mode:"history",
    base: process.env.BASE_URL,
    linkExactActiveClass: 'active',
    routes :[
        {
            path:'/login',
            component:()=>import('@/components/Login/Login.vue'),
            name:'app-login'
        },
        {
            path:'/',
            component:FrontLayout,
            children:[
                {
                    path:'/',
                    component:()=>import('../src/views/Dashboard/Dashboard'),
                    name:'app-dashboard'
                },
                {
                    path:'/financereport',
                    component:()=>import('../src/views/FinanceReport/FinanceReport.vue'),
                    name:'app-financereport'
                },
                {
                    path:'/actionList',
                    name:'ActionList',
                    component:()=>import('@/views/Pages/ActionList/ActionList.vue')
                },
                {
                    path:'/accounts',
                    name:'Accounts',
                    component:()=>import('@/views/Pages/Finance/Accounts/Accounts.vue')
                },

                {
                    path:'/employees',
                    name:'Employees',
                    component:()=>import('@/views/Pages/Employees/Employees.vue')
                },
                {
                    path:'/position',
                    name:'Position',
                    component:()=>import('@/views/Pages/Employees/EmployeePositions/Position.vue')
                },
                {
                    path:'/schedule',
                    name:'Schedule',
                    component:()=>import('@/views/Pages/Employees/Schedule/Schedule.vue')
                },

                {
                    path:'/cows',
                    name:'Cows',
                    component:()=>import('@/views/Pages/Cows/Cows.vue')
                },
                {
                    path:'/cows-group',
                    name:'CowsGroups',
                    component:()=>import('@/views/Pages/Cows/CowsGroups/CowsGroup.vue')
                },
                {
                    path:'/cows-sell',
                    name:'CowSell',
                    component:()=>import('@/views/Pages/Cows/CowSell/CowSell.vue')
                },
                {
                    path:'/cows-born',
                    name:'CowBirth',
                    component:()=>import('@/views/Pages/Cows/CowBirth/CowBirth.vue')
                },
                {
                    path:'/cows-types',
                    name:'CowType',
                    component:()=>import('@/views/Pages/Cows/CowType/CowType.vue')
                },
                {
                    path:'/cows-deaths',
                    name:'CowsDeaths',
                    component:()=>import('@/views/Pages/Cows/CowDeaths/CowDeaths.vue')
                },
                {
                    path:'/clients-list',
                    name:'Clients',
                    component:()=>import('@/views/Pages/Clients/Clients.vue')
                },
                {
                    path:'/clients-price',
                    name:'ClientsPrice',
                    component:()=>import('@/views/Pages/Clients/ClientPrice/ClientsPrice.vue')
                },
                {
                    path:'/clients-payments',
                    name:'ClientsPayments',
                    component:()=>import('@/views/Pages/Clients/ClientPayments/ClientPayments.vue')
                },
                {
                    path:'/salary-payments',
                    name:'SalaryPayments',
                    component:()=>import('@/views/Pages/Employees/SalaryPayments/SalaryPayments.vue')
                },
                {
                    path:'/sheep',
                    name:'Sheep',
                    component:()=>import('@/views/Pages/Sheep/Sheep.vue')
                },
                {
                    path:'/sheep-group',
                    name:'SheepGroup',
                    component:()=>import('@/views/Pages/Sheep/SheepGroups/SheepGroup.vue')
                },
                {
                    path:'/sheep-born',
                    name:'SheepBirth',
                    component:()=>import('@/views/Pages/Sheep/SheepBirth/SheepBirth.vue')
                },
                {
                    path:'/sheep-death',
                    name:'SheepDeath',
                    component:()=>import('@/views/Pages/Sheep/SheepDeath/SheepDeath.vue')
                },
                {
                    path:'/sheep-sales',
                    name:'SheepSales',
                    component:()=>import('@/views/Pages/Sheep/SheepSales/SheepSales.vue')
                },
                {
                    path:'/farms',
                    name:'Farms',
                    component:()=>import('@/views/Pages/Farm/Farms.vue')
                },
                {
                    path:'/feeds',
                    name:'Feeds',
                    component:()=>import('@/views/Pages/Feeds/Feeds.vue')
                },

                    //feed-menu
                {
                    path:'/feed-ins',
                    name: 'FeedIns',
                    component:() => import('@/views/Pages/Feeds/FeedsIns/FeedIns.vue')
                },
                {
                    path:'/feed-spends',
                    name: 'FeedSpends',
                    component:() => import('@/views/Pages/Feeds/FeedSpends/FeedSpends.vue')
                },
                {
                    path:'/feed-totals',
                    name: 'FeedTotals',
                    component:() => import('@/views/Pages/Feeds/FeedTotals/FeedTotals.vue')
                },
                {
                    path:'/feed-transfers',
                    name: 'FeedTransfers',
                    component:() => import('@/views/Pages/Feeds/FeedTransfers/FeedTransfers.vue')
                },
                //feed-menu

                {
                    path:'/fields',
                    name:'Fields',
                    component:()=>import('@/views/Pages/Fields/Fields.vue')
                },
                {
                    path:'/finance',
                    name:'Finance',
                    component:()=>import('@/views/Pages/Finance/Finance.vue')
                },
                {
                    path:'/finance-category',
                    name:'FinanceCategory',
                    component:()=>import('@/views/Pages/Finance/FinanceCategory/FinanceCategory.vue')
                },
                {
                    path:'/milk-category',
                    name:'MilkProdCategory',
                    component:()=>import('@/views/Pages/Finance/FinanceCategory/MilkProdCategory.vue')
                },
                {
                    path:'/milk-sales',
                    name:'MilkSales',
                    component:()=>import('@/views/Pages/Finance/FinanceCategory/MilkSales.vue')
                },
                {
                    path:'/products',
                    name:'Products',
                    component:()=>import('@/views/Pages/Finance/Products/Products.vue')
                },
                {
                    path:'/localization',
                    name:'groups',
                    component:()=>import('@/views/Pages/Localization/Groups/groups.vue')

                },
                {
                    path:'/milkings',
                    name:'Milkings',
                    component:()=>import('@/views/Pages/Milkings/Milkings.vue')

                },
                {
                    path:'/system-variable',
                    name:'SystemVariables',
                    component:()=>import('@/views/Pages/SystemVariables/SystemVariables.vue')

                },
                {
                    path:'/localization/:id',
                    name:'groupItem',
                    component:()=>import('@/views/Pages/Localization/GroupItem/groupItem.vue')
                },
                {
                    path:'/modules',
                    name:'ModuleList',
                    component:()=>import('@/views/Pages/ModuleList/ModuleList.vue')
                },
                {
                    path:'/vats',
                    name:'Vats',
                    component:()=>import('@/views/Pages/Vats/Vats.vue')
                },
                {
                    path:'/users',
                    name:'UserList',
                    component:()=>import('@/views/Pages/Users/Users.vue')
                },
                {
                    path: '/loading',
                    name: 'LoadingComponent',
                    component: () => import('@/components/LoadingComponent.vue')
                },
                {
                    path: '/permissions',
                    name: 'Permissions',
                    component: () => import('@/views/Pages/Permissions/Permissions.vue')
                },
                {
                    path: '/roles',
                    name: 'Roles',
                    component: () => import('@/views/Pages/Roles/Roles.vue')
                },
                {
                    path: '/milk-cost-prices',
                    name: 'MilkCostPrices',
                    component: () => import('@/views/Pages/MIlkCostPrices/MilkCostPrices.vue')
                }
            ]
        },
        {
            path: '*',
            component: () => import('../src/components/NotFoundPage.vue'),
            name: 'NotFoundPage'
        }

    ]
})
router.beforeEach((to, from, next) => {
    const isAuthenticated = !!TokenService.getToken()
    if (to.name !== 'app-login' && !isAuthenticated) {
        return next({name: 'app-login'})
    } else {
        if (to.meta.permission) {
            if (!can(to.meta.permission)) {
                next({path: '/login'})
            }
            next()
        }  else {
            next()
        }
    }
})

export default router;
