import axios from 'axios';
import TokenService from "../services/TokenServices";
import router from '../router';

// export const baseURL = 'http://10.0.40.193:4041/api/';
export const baseURL = 'https://agroterra.plum.uz/api/';
// export const baseURL = 'http://10.0.40.101:4041/api/';
// export const baseURL = 'https://dev-agroterra.plum.uz/api/';


export const http = axios.create({
    baseURL: baseURL
});

function setConfiguration(provider) {
    provider.interceptors.request.use(config => {
            let token = TokenService.getToken();
            if (token) {
                config.headers['Authorization'] = `Bearer ${token}`;
            }
            config.headers['Accept'] = 'application/json';
            config.headers['Access-Control-Allow-Origin'] = '*';
            config.headers['Language'] = localStorage.getItem('lang');
            config.headers['Content-Type'] = 'application/json';
            return config;
        },

        error => Promise.reject(error)
    );
    provider.interceptors.response.use(res => res.data,
        error => {
            if (error.response && error.response.status === 401) {
                TokenService.removeToken();
                router.push({name:'app-login'}).then(()=>{
                }).catch(e=>{
                    console.log(e)
                });
            }
            else if (error.response && (error.response.status === 403)) {
                router.push({name: 'NotFoundPage'}).then();
            }
            return Promise.reject(error)
        });
}
setConfiguration(http)
export default http

