import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale'
import ruLang from "element-ui/lib/locale/lang/ru-RU";
import router from "@/router";
import http from "./plugins/axios";
import Vuex from 'vuex';
import store from "@/store";
import Notifications from 'vue-notification';
import NotificationMixin from "@/mixins/Notification";
import can from "@/can";
import '../public/css/main.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap';
import './mixins/filter'
import helper from "@/mixins/helper";
import i18n from './plugins/i18n.js';
import VueMaskPlugin from 'v-mask'
import { inputNumberMixin } from './mixins/helper';



Vue.mixin(inputNumberMixin);
Vue.use(VueMaskPlugin)
Vue.use(Vuex);
Vue.use(ElementUI);
locale.use(ruLang);
Vue.use(Notifications);

Vue.mixin({methods: {can}});
Vue.mixin(NotificationMixin);
Vue.mixin(helper);
Vue.config.productionTip = false
Vue.prototype.$http = http;
Vue.use(require('vue-moment'));

Vue.use(ElementUI);

new Vue({
  render: h => h(App),
  router,
  store,
  i18n
}).$mount('#app')
