<template>
  <el-container>
    <el-aside class="sidebar">
      <el-menu
          :default-active="getActiveMenuItem"
          class="el-menu-vertical-demo sidebar_menu"
          :collapse="isCollapse"
          background-color="#fff"
          :router="true"
      >
        <div class="logo">
          <img src="../../assets/img/logo.png"/>

        </div>
        <div class="menu-item--content">
          <el-menu-item index="/" :route="{name:'app-dashboard'}">
            <i class="icon-dashboard"></i>
            <span class="ml-10" slot="title">{{ contentLocale.dashboard }}</span>
          </el-menu-item>
          <el-menu-item index="/financereport" :route="{name:'app-financereport'}" v-if="can('inexps.getinexpsbycategory')">
            <i class="icon-Vector"></i>
            <span class="ml-10" slot="title">{{ contentLocale.finance_report }}</span>
          </el-menu-item>
          <el-menu-item v-if="can('milkcostprices.getcostpricebymonth')" index="/milk-cost-prices" :route="{name:'MilkCostPrices'}" >
            <i class="icon-milk-cost-price"></i>
            <span class="ml-10" slot="title">{{ contentLocale.milk_cost_price }}</span>
          </el-menu-item>

          <el-submenu index="finance" :route="{name: 'Finance'}">
            <template slot="title">
              <i  class="icon-wallet"></i>
              <span class="ml-10" slot="title">{{ contentLocale.finance }}</span>
            </template>
            <el-menu-item-group>
              <el-menu-item  v-if="can('inexps.getinexps')" index="/finance" :route="{name: 'Finance'}">
                <i class="icon-dotted"></i>
                <span class="ml-10">{{ contentLocale.finance }}</span>
              </el-menu-item>
              <el-menu-item v-if="can('inexps.getinexps')" index="/finance-category" :route="{name: 'FinanceCategory'}">
                <i class="icon-dotted"></i>
                <span class="ml-10">{{ contentLocale.category }}</span>
              </el-menu-item>
              <el-menu-item v-if="can('accounts.getaccounts')" index="/accounts" :route="{name: 'Accounts'}">
                <i class="icon-dotted"></i>
                <span class="ml-10">{{ contentLocale.accounts }}</span>
              </el-menu-item>
              <el-menu-item v-if="can('inexpcategories.getmilkprodexpcategories')" index="/milk-category" :route="{name: 'MilkProdCategory'}">
                <i class="icon-dotted"></i>
                <span class="ml-10">{{ contentLocale.milk }}</span>
              </el-menu-item>
              <el-menu-item v-if="can('milksales.getsales')" index="/milk-sales" :route="{name: 'MilkSales'}">
                <i class="icon-dotted"></i>
                <span class="ml-10">{{ contentLocale.sell }}</span>
              </el-menu-item>
              <el-menu-item v-if="can('products.getproducts')" index="/products" :route="{name:'Products'}">
                <i class="icon-dotted"></i>
                <span class="ml-10" slot="title">{{ contentLocale.products }}</span>
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>

          <el-submenu index="clients-list">
            <template>
              <div slot="title">
                <i  class="icon-client"></i>
                <span class="ml-10" slot="title">{{ contentLocale.clients }}</span>
              </div>
            </template>
            <el-menu-item-group>
              <el-menu-item v-if="can('clients.getclients')" index="/clients-list" :route="{name:'Clients'}">
                <i class="icon-dotted"></i>
                <span class="ml-10">{{ contentLocale.list }}</span>
              </el-menu-item>
              <el-menu-item v-if="can('clientproductprices.getclientproductprices')" index="/clients-price" :route="{name:'ClientsPrice'}">
                <i class="icon-dotted"></i>
                <span class="ml-10">{{ contentLocale.price }}</span>
              </el-menu-item>
              <el-menu-item v-if="can('clientpayments.getclientpayments')" index="/client-payments" :route="{name:'ClientsPayments'}">
                <i class="icon-dotted"></i>
                <span class="ml-10">{{ contentLocale.payment }}</span>
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>

          <el-submenu index="2">
            <template slot="title">
              <i  class="icon-cow"></i>
              <span class="ml-10">{{ contentLocale.cows }}</span>
            </template>
            <el-menu-item-group>
              <el-menu-item v-if="can('cows.getcows')" index="/cow" :route="{name:'Cows'}">
                <i class="icon-dotted"></i>
                <span class="ml-10">{{ contentLocale.list }}</span>
              </el-menu-item>
              <el-menu-item v-if="can('cowgroups.getcowgroups')" index="/cows-group" :route="{name:'CowsGroups'}">
                <i class="icon-dotted"></i>
                <span class="ml-10">{{ contentLocale.group }}</span>
              </el-menu-item>
              <el-menu-item v-if="can('cowsales.getcowsales')" index="/cows-sell" :route="{name:'CowSell'}">
                <i class="icon-dotted"></i>
                <span class="ml-10">{{ contentLocale.sell }}</span>
              </el-menu-item>
              <el-menu-item v-if="can('cowbirths.getcowbirths')" index="/cows-birth" :route="{name:'CowBirth'}">
                <i class="icon-dotted"></i>
                <span class="ml-10">{{ contentLocale.born }}</span>
              </el-menu-item>
              <el-menu-item v-if="can('cowtypes.getcowtypes')" index="/cows-types" :route="{name:'CowType'}">
                <i class="icon-dotted"></i>
                <span class="ml-10">{{ contentLocale.types }}</span>
              </el-menu-item>
              <el-menu-item v-if="can('cowdeaths.getcowdeaths')" index="/cows-deaths" :route="{name:'CowsDeaths'}">
                <i class="icon-dotted"></i>
                <span class="ml-10">{{ contentLocale.deaths }}</span>
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="4">
            <template slot="title">
              <i  class="icon-sheep"></i>
              <span class="ml-10" slot="Бараны">{{ contentLocale.sheeps }}</span>
            </template>
            <el-menu-item-group>
              <el-menu-item v-if="can('sheeps.getsheeps')" index="/sheep" :route="{name:'Sheep'}">
                <i class="icon-dotted"></i>
                <span class="ml-10">{{ contentLocale.list }}</span>
              </el-menu-item>
              <el-menu-item v-if="can('sheepgroups.getsheepgroups')" index="/sheep-group" :route="{name:'SheepGroup'}">
                <i class="icon-dotted"></i>
                <span class="ml-10">{{ contentLocale.group }}</span>

              </el-menu-item>
              <el-menu-item v-if="can('sheepsales.getsheepsales')" index="/sheep-sales" :route="{name:'SheepSales'}">
                <i class="icon-dotted"></i>
                <span class="ml-10">{{ contentLocale.sell }}</span>
              </el-menu-item>
              <el-menu-item v-if="can('sheepbirths.getsheepbirths')" index="/sheep-born" :route="{name:'SheepBirth'}">
                <i class="icon-dotted"></i>
                <span class="ml-10">{{ contentLocale.born }}</span>
              </el-menu-item>
              <el-menu-item v-if="can('sheepdeaths.getsheepdeaths')" index="/sheep-death" :route="{name:'SheepDeath'}">
                <i class="icon-dotted"></i>
                <span class="ml-10">{{ contentLocale.deaths }}</span>
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="5">
            <template slot="title">
              <i class="icon-feeds"></i>
              <span class="ml-10" slot="title">
              {{ contentLocale.feeds }}
            </span>
            </template>
            <el-menu-item-group>
              <el-menu-item v-if="can('farmlands.getfeeds')" index="/feeds" :route="{name:'Feeds'}">
                <i class="icon-dotted"></i>
                <span class="ml-10">{{ contentLocale.types }}</span>
              </el-menu-item>
              <el-menu-item v-if="can('feedins.getfeedins')" index="/feed-ins" :route="{name: 'FeedIns'}">
                <i class="icon-dotted"></i>
                <span class="ml-10">{{ contentLocale.coming }}</span>
              </el-menu-item>
              <el-menu-item v-if="can('feedspends.getfeedspends')" index="/feed-spends" :route="{name: 'FeedSpends'}">
                <i class="icon-dotted"></i>
                <span class="ml-10">{{ contentLocale.consumption }}</span>
              </el-menu-item>
              <el-menu-item v-if="can('feedtotals.getfeedtotalsallfarms')" index="/feed-totals" :route="{name: 'FeedTotals'}">
                <i class="icon-dotted"></i>
                <span class="ml-10">{{ contentLocale.overallvolume }}</span>
              </el-menu-item>
              <el-menu-item v-if="can('feedtransfers.getfeedtransfers')" index="/feed-transfers" :route="{name: 'FeedTransfers'}">
                <i class="icon-dotted"></i>
                <span class="ml-10">{{ contentLocale.transfers }}</span>
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>

          <el-menu-item v-if="can('farms.getfarms')" index="/farms" :route="{name:'Farms'}">
            <i class="icon-farm"></i>
            <span class="ml-10" slot="title">{{ contentLocale.farms }}</span>
          </el-menu-item>
          <el-menu-item v-if="can('milkings.getmilkings')" index="/milkings" :route="{name:'Milkings'}">
            <i class="icon-milking"></i>
            <span class="ml-10" slot="title">{{ contentLocale.milking }}</span>
          </el-menu-item>
          <el-menu-item v-if="can('farmlands.getfields')" index="/fields" :route="{name:'Fields'}">
            <i class="icon-fields"></i>
            <span class="ml-10" slot="title">{{ contentLocale.fields }}</span>
          </el-menu-item>

          <el-submenu index="/6" :route="{name: 'Employees'}">
            <template slot="title">
              <i class="icon-user"></i>
              <span slot="title">
              {{ contentLocale.employees }}
            </span>
            </template>
            <el-menu-item-group>
              <el-menu-item v-if="can('employees.getemployees')" index="/employees" :route="{name: 'Employees'}">
                <i class="icon-dotted"></i>
                {{ contentLocale.employee }}
              </el-menu-item>
              <el-menu-item v-if="can('employeepositions.getemployeepositions')" index="/position" :route="{name: 'Position'}">
                <i class="icon-dotted"></i>
                {{ contentLocale.position }}
              </el-menu-item>
              <el-menu-item v-if="can('employeeschedules.getemployeeschedules')" index="/schedule" :route="{name: 'Schedule'}">
                <i class="icon-dotted"></i>
                {{ contentLocale.schedule }}
              </el-menu-item>
              <el-menu-item v-if="can('salarypayments.getsalarypayments')" index="/salary-payments" :route="{name:'SalaryPayments'}">
                <i class="icon-dotted"></i>
                {{ contentLocale.salarypayments }}
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-menu-item v-if="can('localization.getgroups')" index="/localization" :route="{name:'groups'}" >
            <i class="icon-localization"></i>
            <span class="ml-10" slot="title">{{ contentLocale.localization }}</span>
          </el-menu-item>
          <el-menu-item v-if="can('auth.getusers')" index="/users" :route="{name:'UserList'}">
            <i class="icon-users"></i>
            <span class="ml-10" slot="title">{{ contentLocale.users}}</span>
          </el-menu-item>
          <el-menu-item v-if="can('auth.getmodules')" index="/modules" :route="{name:'ModuleList'}">
            <i class="icon-Folder"></i>
            <span class="ml-10" slot="title">{{ contentLocale.modules }}</span>
          </el-menu-item>
          <el-menu-item v-if="can('system.getvariables')" index="/system-variable" :route="{name:'SystemVariables'}">
            <i class="icon-system"></i>
            <span class="ml-10" slot="title">{{ contentLocale.system }}</span>
          </el-menu-item>
          <el-menu-item v-if="can('vats.gettransactions')" index="/vats" :route="{name:'Vats'}">
            <i class="icon-nds"></i>
            <span class="ml-10" slot="title">{{ contentLocale.vats }}</span>
          </el-menu-item>
          <el-menu-item v-if="can('auth.getpermissions')" index="/permissions" :route="{name:'Permissions'}">
            <i class="icon-permissions"></i>
            <span class="ml-10" slot="title">{{ contentLocale.permissions }}</span>
          </el-menu-item>
          <el-menu-item v-if="can('auth.getroles')" index="/roles" :route="{name:'Roles'}">
            <i class="icon-role"></i>
            <span class="ml-10" slot="title">{{ contentLocale.role }}</span>
          </el-menu-item>
        </div>
      </el-menu>
      <!--    end main container-->
    </el-aside>
    <el-container class="sidenav-main">
      <!--        header-->
      <el-header class="header">
        <div class="d-flex align-items-center">
          <div class="toogle_btn" @click="expandSidenav">
            <i class="el-icon-arrow-right" v-if="isCollapse"></i>
            <i class="el-icon-arrow-left" v-else></i>
          </div>

          <div class="welcome_msg">
            <h5 class="color-black font-weight-700">{{contentLocale.welcome}} :</h5>
            <div>
              <span>{{users.lastName + ' ' + users.firstName}}</span>
              <span>{{users.roleName}}</span>
            </div>
          </div>
        </div>

        <div class="lang_user">
          <div>


          </div>
          <el-dropdown @command="changeLanguage">
            <span class="el-dropdown-link">
              <img src="../../assets/img/globe.png" alt="">
              {{langName($i18n.locale)  }}
            </span>
            <el-dropdown-menu >
              <div v-for="(item,i) in LANGUAGES" :key="i">
                <el-dropdown-item :command="item.key" >{{item.name}}</el-dropdown-item>
              </div>

            </el-dropdown-menu>
          </el-dropdown>
          <el-tooltip :content="contentLocale.usdrate" class="ml-10">
            <el-button class="change_password__btn" icon="el-icon-money" @click="showChangeDollar"> ${{usdRates | financialFormatNumber }}</el-button>
          </el-tooltip>
          <el-tooltip :content="contentLocale.changepassword" class="ml-10">

              <i class="icon-system" style="font-size: 24px; cursor: pointer" @click="showChangePasswordModal"></i>

          </el-tooltip>



          <el-tooltip :content="contentLocale.exit" class="ml-10 cursor-pointer">
            <div @click="logOut">
              <img src="../../assets/img/Logout.png" alt="" width="24" height="24">
            </div>
          </el-tooltip>


        </div>
      </el-header>
      <!--        end header-->
      <!--        main body-->
      <el-main class="main">
        <router-view></router-view>
      </el-main>
      <!--        end main body-->
      <!--        footer-->
      <!--<el-footer> </el-footer>-->
      <!--        end footer-->
    </el-container>
    <el-dialog
        :title="contentLocale.changepassword"
        :visible="isPasswordModal"
        width="33%"
        @close="isPasswordModal= false"
        :close-on-click-modal="true"
        :show-close="false"
        class="my-modal"
    >
      <div class="modal-content">
        <el-form  hide-required-asterisk label-position="top" :model="changePasswordForm " :rules="formRules" ref="myForm" label-width="120px">
              <el-form-item prop="currentPassword" class="px-1" :label="contentLocale.currentpassword">
                <el-input type="text" show-password :placeholder="contentLocale.currentpassword" v-model="changePasswordForm.currentPassword"></el-input>
              </el-form-item>

              <el-form-item prop="newPassword"  class="px-1" :label="contentLocale.newpassword">
                <el-input type="text" show-password :placeholder="contentLocale.newpassword"  v-model="changePasswordForm.newPassword"></el-input>
              </el-form-item>
          <el-form-item prop="confirmNewPassword"   class="px-1" :label="contentLocale.confirmnewpassword">
            <el-input type="text" show-password :placeholder="contentLocale.confirmnewpassword"  v-model="changePasswordForm.confirmNewPassword"></el-input>
          </el-form-item>

        </el-form>
      </div>
      <div class="dialog-footer">
        <el-button type="success" v-loading="isSaveBtnLoading" @click="saveChangePassword">{{ contentLocale.edit }}</el-button>
        <el-button @click="isPasswordModal = false" type="info">{{ contentLocale.cancel }}</el-button>
      </div>
    </el-dialog>
    <el-dialog
        :title="contentLocale.usdrate"
        :visible="isUsdRateModal"
        width="33%"
        @close="isUsdRateModal= false"
        :close-on-click-modal="true"
        :show-close="false"
        class="my-modal"
    >
      <div class="modal-content">
        <el-form label-position="top"  label-width="120px">
          <el-form-item prop="currentPassword" class="px-1" :label="contentLocale.usdrate">
            <el-input type="text"  :placeholder="contentLocale.usdrate" v-model="usdRate" @input="usdRate = inputNumberType(usdRate)"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="dialog-footer">
        <el-button type="success"  @click="saveUsdChange">{{ contentLocale.add }}</el-button>
        <el-button @click="isUsdRateModal = false" type="info">{{ contentLocale.cancel }}</el-button>
      </div>
    </el-dialog>
  </el-container>

</template>

<script>
import Employees from "@/views/Pages/Employees/Employees.vue";

export default {
  name: "SideNav",
  data(){
    return{
      isCollapse:false,
      activeLink: null,
      langs:[
        {
          key:'uzl',
          name:'O\'zbekcha'
        },
        {
          key:'uzc',
          name:'Ўзбекча'
        },
        {
          key:'ru',
          name:'Русский'
        },
      ],
      users:[],
      isPasswordModal:false,
      changePasswordForm:{
        currentPassword:'',
        newPassword:'',
        confirmNewPassword:'',
      },
      usdRates:null,

      formRules:{
        currentPassword:[
          {
            required:true,
            trigger:'blur',
            message:'Жорий паролни киритинг'
          }
        ],
        newPassword:[
          {
            required:true,
            trigger:'blur',
            message:'Янги паролни киритинг'
          }
        ],
        confirmNewPassword:[
          {
            validator:this.validatePasswordConfirm,
            trigger:'blur',
            message:'Янги паролни тасдиқланг'
          }
        ]
      },
      isSaveBtnLoading:false,
      windowWidth: window.innerWidth,
      isUsdRateModal:false,
      usdRate: null

    }
  },
  methods: {
    inputNumberType(value) {
      if (value) {
        return String(value).replace(/\D/g, '').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
      }
      return value;
    },
    showChangeDollar(){
      this.usdRate = this.usdRates?.toLocaleString();
        this.isUsdRateModal = true;

    },
    saveUsdChange(){
      this.usdRate = Number(this.usdRate?.replace(/\D/g,''))
      this.$http.post('Helpers/usdRate',{},{
        params:{
          rate: this.usdRate
        }
      }).then(res=>{
        if (res.success){
          this.usdRates = res?.result;
          this.isUsdRateModal = false;
          this.successNotify()
        }
      })
          .catch(e=>{
            this.errorNotify(this.contentLocaleBack[e?.response?.data?.error?.message?.toLowerCase()])
          })
    },
    usdRatess(){
       this.$http.get('Helpers/usdRate').then(res=>{
        this.usdRates = res?.result;
      })
          .catch(e=>{
            this.errorNotify(this.contentLocaleBack[e?.response?.data?.error?.message?.toLowerCase()])
          })
    },
    validatePasswordConfirm(rule, value, callback) {
      if (value !== this.changePasswordForm.newPassword) {
        callback(new Error('Passwords do not match'));
      } else {
        callback();
      }
      },
   async logOut(){
      localStorage.clear();
      await this.$router.push({name: 'app-login'});
    },
   async saveChangePassword(){
      const valid = await new Promise(resolve => {
        this.$refs.myForm.validate(resolve);
      });

      if (valid) {
        this.isSaveBtnLoading = true;
        try {
          const { result } = await this.$http.post('AuthUser/changePassword', this.changePasswordForm);
            if (result){
              this.successNotify();
              this.isPasswordModal = false;
              await this.logOut();
            }

        } catch (e) {
          this.errorNotify(this.contentLocaleBack[e?.response?.data?.error?.message?.toLowerCase()]);
        }
        finally {
          this.isSaveBtnLoading = false;
        }
      }
    },
   async getUserInfo(){
      await this.$http.get('AuthUser/getMyInfo').then(res=>{
        this.users = res?.result;
      })
          .catch(e=>{
            this.errorNotify(this.contentLocaleBack[e?.response?.data?.error?.message?.toLowerCase()])
          })
    },
    showChangePasswordModal(){
      this.changePasswordForm.currentPassword = '';
      this.changePasswordForm.newPassword = '';
      this.changePasswordForm.confirmNewPassword = '';
      this.isPasswordModal = true;
    },
    expandSidenav(){
      this.isCollapse = !this.isCollapse;
    },
    changeLanguage(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem('lang', lang);
      this.getLocalizedValues().then();
      this.getLocalizedValuesBack().then();
    },
    langName(name){
      switch (name){
        case 'uzc':
            return 'Ўз'
        case 'ru':
          return 'Ру'
        default:
            return 'Uz'
      }

    },
    async getLocalizedValues() {
      try {
        const res = await this.$store.dispatch('getValuesByLang');
        this.$store.commit('changeLocale', res.result);
      } catch (e) {
        this.errorNotify(e?.response?.data?.error?.message ?? e)
      }
    },
    async getLocalizedValuesBack() {
      try {
        const res = await this.$store.dispatch('getValuesBack');
        this.$store.commit('changeLocaleBack', res.result);
      } catch (e) {
        this.errorNotify(e?.response?.data?.error?.message ?? e)
      }
    },
  },
 async created() {
     await this.getUserInfo()
    await this.usdRatess()
  },
  mounted() {
    let windowSize = window.innerWidth;
    if (windowSize<=876){
      this.isCollapse = true;
    }

    window.onresize = () => {
      this.windowWidth = window.innerWidth
      if (this.windowWidth <= 876){
        this.isCollapse = true;
      }
      else this.isCollapse = false;
    }
  },
  computed:{
    Employees() {
      return Employees
    },
    getActiveMenuItem(){
      return this.$route.path;
    },
    LANGUAGES() {
      return this.langs.filter((lang) => lang.key !== this.$i18n.locale);
    },
  }
}
</script>

<style scoped>
.menu-link{
  text-decoration: none;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 230px;
  height: 100%;
}
.menu-button:hover,
.menu-button:focus {

}
.menu-button {
  border: none;
  padding-bottom: 28px;
  font-size: 25px;
  background: transparent;
  color: #fff;
  padding-left: 0px!important;
}
>>>.el-menu{
  border: none;
}
>>>.el-menu-vertical-demo{
  width: 64px;
  min-height: 100vh;
  background-color: #fff;

}
>>>.el-menu--collapse .el-submenu__title span{
  display: none;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  min-height: 100vh;
  height: fit-content;
}
>>>.el-menu--inline{
  background: #fff;
}
.logo{
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  position: relative;
}
.logo img{
  width: 70%;
}
.logo span{
  color: #fff;
  font-size: 15px;
  text-transform: uppercase;
  margin-left: 5px;
}
 .toogle_btn{
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  cursor: pointer;
  box-shadow: 0px 14px 34px 0px rgba(0, 0, 0, 0.15);
   margin-right: 15px;

}
.sidebar {
  overflow-x: hidden;
  overflow-y: auto;
  width: auto !important;
  min-width: 0 !important;
  height: 100vh !important;
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
}
.header {
  height: 60px !important;
  background: #fff;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;

}
.sidenav-main{
  background: #F6F7FA;
  height: 100vh;
}
.el-menu-item{
  border-right: 3px solid transparent;
}
.el-menu-item,
>>>.el-menu-item,
>>>.el-submenu,
>>>.el-submenu__title
{
  background: #fff !important;

}
>>>.el-menu-item-group__title{
  display: none;
}
.el-menu-item:focus,
.el-menu-item:hover,
>>>.el-menu-item.is-active,
.el-submenu:hover,
.el-submenu__title:hover,
  >>>.el-submenu.is-active.is-opened
{
  background: #a3f1d1 !important;
  border-right-color: rgba(52, 152, 104, 1);
  color: rgba(52, 152, 104, 1) !important;
}
.el-menu-item:focus>i:before,
.el-menu-item:hover>i:before,
>>>.el-menu-item.is-active>i:before,
.el-submenu:hover>i:before,
.el-submenu__title:hover>i:before,
>>>.el-submenu.is-active.is-opened .el-submenu__title i:before{
  color: rgba(52, 152, 104, 1) !important;
}
.el-menu-item:focus>span,
.el-menu-item:hover>span,
>>>.el-menu-item.is-active>span,
.el-submenu:hover>span,
.el-submenu__title:hover>span,
>>>.el-submenu.is-active.is-opened .el-submenu__title  span{
  color: rgb(52, 155, 106) !important;
}
>>>.el-submenu__title:hover{
  background: rgba(255, 255, 255, 0.15) !important;
  border-right-color: #ffffff;
  background: none;
}
.el-menu-item,
.el-submenu,
>>>.el-submenu__title{
  color: #1A1C1E!important;
  font-size: 15px;
  cursor: pointer;
  font-weight: 600;
  background-color: #ffff
}
.el-menu-item i,
.el-submenu i{
  font-size: 20px !important;
  font-weight: 500;
}
.el-submenu .el-menu-item i{
  font-size: 10px !important;
  font-weight: 600;
}
>>>.el-header{
  padding-left: 10px!important;
}
>>>.el-menu.el-menu--popup.el-menu--popup-right-start{
  background-color: #ffff !important;
}
.lang_user{
  color: white;
  display: flex;
  align-items: center;
}
.el-dropdown-link{
  display: flex;
  align-items: center;
  margin-left: 20px;
  color: rgba(26, 28, 30, 1);
  font-weight: 500;
}
.user_name{
  margin-left: 10px;
}
.change_password__btn{
  margin-left: 20px;
  background: rgba(52, 152, 104, 1);
  color: white;
  padding: 5px 10px;
  font-size: 18px;

}

.el-menu-item{
  cursor: pointer;
}
.welcome_msg{
  color: #1A1C1E;
  display: flex;
  align-items: center;

}
.welcome_msg h5{
  font-weight: 700;
}
.welcome_msg div{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-left: 10px;
  line-height: normal;
  margin-top: 8px;
}
.welcome_msg div span:first-child{
  font-size: 18px;
  color: #1A1C1E;
  font-weight: 500;
}
.welcome_msg div span:last-child{
  color: #1A1C1E;
  pointer-events: none;
}
.el-menu-item, .el-submenu__title{
  height: 44px;
  line-height: 44px;
}
</style>
